<template>
  <modal v-model="invalidAccumulationLevelDialogOpen" center-buttons>
    <v-layout justify-center>
      <v-flex xs10 sm8 md10>
        <typography type="h5">
          Changes to the loyalty accumulation level (e.g. "Cost Centre" to
          "Account") cannot be done through this form. Please contact your Z
          Energy Customer Manager.
        </typography>
        <typography>
          You can however update or change your loyalty number via this form
        </typography>
      </v-flex>
    </v-layout>

    <round-button slot="left-button" @click="onOk">OK</round-button>
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';

export default {
  components: {
    Modal,
    Typography,
    RoundButton
  },
  computed: {
    invalidAccumulationLevelDialogOpen: {
      get() {
        return this.$store.state.ui.invalidAccumulationLevelDialogOpen;
      },
      set(value) {
        this.$store.dispatch(
          'ui/toggleInvalidAccumulationLevelDialogOpen',
          value
        );
      }
    }
  },
  methods: {
    onOk() {
      this.invalidAccumulationLevelDialogOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
</style>

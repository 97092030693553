<template>
  <form-section :id="sectionId" @visibility="onChangeSectionVisibility" v-if="accountNumberSectionComplete">
    <form-group>
      <v-layout>
        <v-flex>
          <v-layout :justify-center="$vuetify.breakpoint.xsOnly" align-center>
            <v-flex xs12 md11>
              <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mb-4 align-center>
                <v-flex xs12>
                  <typography type="h5">Earn Airpoints Dollars&trade;</typography>
                  <typography>Register your Airpoints&trade; number.</typography>
                </v-flex>
              </v-layout>

              <template v-for="(associationDetail, index) in associationDetails">
                <v-layout row wrap xs12 :key="index" align-center class="loyalty-row" :id="loyaltyTypeId + index">
                  <v-flex xs3 class="loyalty-cell" v-if="associationDetails.length > 1 && editIndex !== index">
                    <div class="scheme-heading">Cost centre</div>
                    <div>{{ associationDetail.costCentreCode }}</div>
                  </v-flex>
                  <v-flex xs6 class="loyalty-cell" v-if="associationDetails.length > 1 && editIndex !== index">
                    <div class="scheme-heading">{{ 'Airpoints&trade;' }} number</div>
                    <div>{{ associationDetail.airpointsNumber }}</div>
                  </v-flex>
                  <v-flex xs3 class="loyalty-cell" v-if="associationDetails.length > 1 && editIndex !== index">
                    <v-layout row justify-space-around>
                      <text-button class="edit-button" @click="onEditCostCentreDetails(index)"
                        :disabled="editIndex !== null">Edit</text-button>

                      <text-button ml-5 @click="onRemoveCostCentreDetails(index)" v-if="associationDetails.length > 1"
                        :disabled="editIndex !== null" :name="`RemoveCostCentreDetails${index}`">Remove</text-button>
                    </v-layout>
                  </v-flex>

                  <LoyaltyDetailsOption :key="index" v-if="editIndex === index || associationDetails.length === 1"
                    :associationDetailIndex="index" :associationDetail="associationDetail" />
                </v-layout>
              </template>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 md10>
          <round-button outline name="AddCostCentreDetails" @click="onAddCostCentreDetails"
            v-if="hasCostCentres && associationDetails[0].loyaltyScheme && editIndex === null">Add
            another</round-button>
        </v-flex>
      </v-layout>
      <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mt-5>
        <round-button v-if="!loyaltyDetailsSectionComplete" @click="onNextSection"
          :disabled="!associationDetailsIsValid">Next: Terms &amp; conditions</round-button>
      </v-layout>
    </form-group>
  </form-section>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import FormGroup from '@/components/atoms/FormGroup.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import TextButton from '@/components/atoms/TextButton.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { mapGetters, mapState } from 'vuex';
import {
  SECTION_TERMS_AND_CONDITIONS,
  SECTION_LOYALTY_DETAILS,
  FIELD_LOYALTY_TYPE,
  FIELD_LOYALTY_TYPE_AIRPOINTS,
} from '@/constants/html-ids';
import { BRAND } from '@/constants/form';
import AirpointsIcon from '@/assets/icons/airpoints.svg';
import AirpointsWhiteIcon from '@/assets/icons/airpoints-white.svg';
import AirpointsMobileIcon from '@/assets/icons/airpoints-mobile.svg';
import AirpointsMobileWhiteIcon from '@/assets/icons/airpoints-mobile-white.svg';
import ToggleButtonWrapper from '@/components/atoms/ToggleButtonWrapper.vue';
import ToggleButton from '@/components/molecules/ToggleButton.vue';
import LoyaltyDetailsOption from '@/components/molecules/LoyaltyDetailsOption.vue';

export default {
  components: {
    Typography,
    FormSection,
    FormGroup,
    Checkbox,
    RoundButton,
    TextButton,
    TextInput,
    MobileFullPageWrapper,
    ToggleButtonWrapper,
    ToggleButton,
    AirpointsIcon,
    AirpointsMobileIcon,
    AirpointsWhiteIcon,
    AirpointsMobileWhiteIcon,
    LoyaltyDetailsOption
  },
  computed: {
    editIndex: {
      get() {
        return this.$store.state.loyaltyDetails.editIndex;
      },
      set(value) {
        this.$store.dispatch('loyaltyDetails/setEditIndex', value);
      }
    },
    ...mapGetters({
      associationDetailsIsValid: 'loyaltyDetails/associationDetailsIsValid'
    }),
    ...mapState({
      associationDetails: state => state.loyaltyDetails.associationDetails,
      accountNumberSectionComplete: state =>
        state.accountNumber.sectionComplete,
      loyaltyDetailsSectionComplete: state =>
        state.loyaltyDetails.sectionComplete,
      loyaltyScheme: state => state.loyaltyDetails.loyaltyScheme,
      hasCostCentres: state => state.associationLevel.hasCostCentres
    }),
    sectionId: () => SECTION_LOYALTY_DETAILS,
    loyaltyTypeId: () => FIELD_LOYALTY_TYPE,
    airpointsId: () => FIELD_LOYALTY_TYPE_AIRPOINTS,
    brand: () => BRAND
  },
  methods: {
    onAddCostCentreDetails() {
      this.$store.dispatch('loyaltyDetails/addCostCentreDetails');

      this.editIndex = this.associationDetails.length - 1;
    },
    onRemoveCostCentreDetails(index) {
      this.$store.dispatch('ui/toggleRemoveLoyaltyDetailsDialogOpen', {
        isOpen: true,
        index
      });
    },
    async onNextSection() {
      this.$store.dispatch('loyaltyDetails/changeSectionComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${SECTION_TERMS_AND_CONDITIONS}`);
    },
    onEditCostCentreDetails(index) {
      this.editIndex = index;
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch(
          'navigation/addSectionInView',
          SECTION_LOYALTY_DETAILS
        );
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_LOYALTY_DETAILS
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.loyalty-row {
  padding: 10px;
  font-size: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #eee;
}

.loyalty-cell {
  padding: 10px;
  text-align: left;
}

.scheme-heading {
  font-size: 70%;
  line-height: 60%;
  white-space: nowrap;
}

.edit-button {
  margin-right: 10px;
}
</style>

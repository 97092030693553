<template>
  <div
    :class="['form-group', {'form-group--no-margin': noMargin}, {'form-group--visible': isVisible}]"
  >
    <div v-observe-visibility="onVisibilityChange" class="visibility-sensor"/>
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    noMargin: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: true
    }
  },
  beforeDestroy() {
    this.$emit('visibility', false);
  },
  methods: {
    onVisibilityChange(isVisible, entry) {
      this.$emit('visibility', isVisible, entry);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: rem(0 0 20vh 0);
  width: 100%;
  opacity: 1; // was 0.3, removed for now due to issue with IntersectionObserver not working correctly sometimes
  transition: all 0.3s ease;
  min-height: 40vh;
  // border: 1px solid red; // debug indicator
  &--no-margin {
    margin: 0;
  }

  &--visible {
    opacity: 1;
  }

  /deep/ > .layout {
    width: 100%;
  }
}

.visibility-sensor {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(0, -50%);
  background-color: green; // Debug indicator
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
</style>

<template>
  <form-section :id="sectionId" @visibility="onChangeSectionVisibility">
    <form-group>
      <mobile-full-page-wrapper :go-to-id="rewardLevelId" show-arrow>
        <typography type="h3">Thanks, {{ firstName }}. Now we need to know if you would like to earn rewards at account
          level or cost centre level.</typography>

        <typography>You can link one Airpoints&trade; number with your account or a cost centre if your
          account has been set up that way.</typography>
      </mobile-full-page-wrapper>

      <mobile-full-page-wrapper :id="rewardLevelId">
        <v-layout :mt-5="$vuetify.breakpoint.smAndUp" :justify-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs8>
            <typography type="h5">Which level would you like to earn your rewards?</typography>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex>
            <toggle-button-wrapper>
              <toggle-button :active="accumulationLevel === accountLevel" :value="accountLevel"
                :label="rewardLevelLabels[accountLevel]" name="AccountLevel" @click="onAccumulationLevelChange" />

              <toggle-button :active="accumulationLevel === costCentreLevel" :value="costCentreLevel"
                :label="rewardLevelLabels[costCentreLevel]" name="CostCentreLevel" @click="onAccumulationLevelChange" />
              <div class="cost-centre-warning" v-if="accumulationLevel === costCentreLevel">
                Rewards cannot be earnt at cost centre level if you are a Z Business Plus customer.
                If you have any questions about your offer, please call the team at Z on
                <a href="tel:0800 936 374">0800 Z ENERGY (0800 936 374)</a>
              </div>
            </toggle-button-wrapper>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
      <mobile-full-page-wrapper v-if="accumulationLevel === costCentreLevel" :id="costCentresId">
        <div>
          <typography type="h5">Has your {{ brand }} account been set up with cost centres?</typography>
          <typography>If you are not set up with cost centre please select account level.</typography>
          <v-layout justify-center>
            <v-flex sm12>
              <toggle-button-wrapper>
                <toggle-button :active="hasCostCentres === hasCostCentresYes" :value="hasCostCentresYes" label="Yes"
                  name="HasCostCentresYes" @click="onHasCostCentresChange" />
                <toggle-button :active="hasCostCentres === hasCostCentresNo" :value="hasCostCentresNo" label="No"
                  name="HasCostCentresNo" @click="onHasCostCentresChange" />
              </toggle-button-wrapper>
            </v-flex>
          </v-layout>
        </div>
      </mobile-full-page-wrapper>
      <v-flex class="no-grow" v-if="!associationLevelSectionComplete">
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs10 sm6 md12>
            <round-button :disabled="$v.$invalid" @click="onNextSectionClick">Next: Account number</round-button>
          </v-flex>
        </v-layout>
      </v-flex>
    </form-group>
  </form-section>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import ToggleButtonWrapper from '@/components/atoms/ToggleButtonWrapper.vue';
import ToggleButton from '@/components/molecules/ToggleButton.vue';
import FormGroup from '@/components/atoms/FormGroup.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { mapGetters, mapState } from 'vuex';
import {
  SECTION_ASSOCIATION_LEVEL,
  SECTION_ACCOUNT_NUMBER,
  SECTION_LOYALTY_DETAILS,
  SECTION_TERMS_AND_CONDITIONS,
  FIELD_ASSOCIATION_REWARD_LEVEL,
  FIELD_ASSOCIATION_COST_CENTRES
} from '@/constants/html-ids';
import {
  BRAND,
  ACCOUNT_LEVEL,
  COST_CENTRE_LEVEL,
  REWARD_LEVEL_LABELS
} from '@/constants/form';
import { sameAs, required } from 'vuelidate/lib/validators';
import { navigationOffset } from '@/helpers/navigation';

export default {
  components: {
    Typography,
    FormSection,
    ToggleButton,
    ToggleButtonWrapper,
    FormGroup,
    Checkbox,
    RoundButton,
    TextInput,
    MobileFullPageWrapper
  },
  watch: {
    '$v.$invalid': function (value) {
      this.$store.dispatch('associationLevel/changeInvalid', value);
    }
  },
  computed: {
    accountLevel: () => ACCOUNT_LEVEL,
    costCentreLevel: () => COST_CENTRE_LEVEL,
    ...mapState({
      accumulationLevel: state => state.associationLevel.accumulationLevel,
      hasCostCentres: state => state.associationLevel.hasCostCentres,
      associationLevelSectionComplete: state =>
        state.associationLevel.sectionComplete
    }),
    ...mapGetters({
      firstName: 'landing/firstName'
    }),
    sectionId: () => SECTION_ASSOCIATION_LEVEL,
    rewardLevelId: () => FIELD_ASSOCIATION_REWARD_LEVEL,
    costCentresId: () => FIELD_ASSOCIATION_COST_CENTRES,
    rewardLevelLabels: () => REWARD_LEVEL_LABELS,
    brand: () => BRAND,
    hasCostCentresNo: () => false,
    hasCostCentresYes: () => true
  },
  methods: {
    async onAccumulationLevelChange(value) {
      this.$store.dispatch('associationLevel/setAccumulationLevel', value);
      // reset hasCostCenters back to null, helps with validation
      this.$store.dispatch('associationLevel/setHasCostCentres', null);

      await this.$nextTick();

      if (value === COST_CENTRE_LEVEL) {
        // Following disabled to ensure user notices warning message
        // this.$vuetify.goTo(`#${FIELD_ASSOCIATION_COST_CENTRES}`, {
        //   offset: navigationOffset(FIELD_ASSOCIATION_COST_CENTRES)
        // });
        this.resetFormState();
      }
      this.$store.dispatch('loyaltyDetails/resetLoyaltyScheme');
      this.$store.dispatch('loyaltyDetails/changeSectionComplete', null);
      this.$store.dispatch('termsAndConditions/changeSectionComplete', null);
    },
    async onHasCostCentresChange(value) {
      this.$store.dispatch('associationLevel/setHasCostCentres', value);

      if (value === this.hasCostCentresNo) {
        this.$store.dispatch('ui/toggleNoCostCentresDialogOpen', true);
        this.resetFormState();
      }
    },
    resetFormState() {
      // Reset some form state to prevent user from completing form
      // if changing to No cost centres
      this.$store.dispatch('associationLevel/changeSectionComplete', null);
      this.$store.dispatch('accountNumber/changeSectionComplete', null);
      this.$store.dispatch('loyaltyDetails/changeSectionComplete', null);
      this.$store.dispatch('termsAndConditions/changeSectionComplete', null);

      this.$store.dispatch(
        'navigation/removeSectionInView',
        SECTION_ACCOUNT_NUMBER
      );

      this.$store.dispatch(
        'navigation/removeSectionInView',
        SECTION_LOYALTY_DETAILS
      );

      this.$store.dispatch(
        'navigation/removeSectionInView',
        SECTION_TERMS_AND_CONDITIONS
      );
    },
    async onNextSectionClick() {
      this.$store.dispatch('associationLevel/changeSectionComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${SECTION_ACCOUNT_NUMBER}`);
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch(
          'navigation/addSectionInView',
          SECTION_ASSOCIATION_LEVEL
        );
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_ASSOCIATION_LEVEL
        );
      }
    }
  },
  validations: {
    accumulationLevel: {
      notNull: function (value) {
        return value != null;
      }
    },
    hasCostCentres: {
      sameAs: sameAs(nestedModel => {
        if (nestedModel.accumulationLevel === ACCOUNT_LEVEL) {
          return null;
        } else {
          return true;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.cost-centre-warning {
  position: relative;
  padding: 1rem 1.25rem;
  max-height: 9.375rem;
  border: 1px solid #f43a3a;
  border-radius: 5px;
  border-width: 2px;

  @include sm-and-down {
    max-width: 100%;
  }

  @include md {
    max-width: 22rem;
  }
}
</style>

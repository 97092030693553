<template>
  <v-layout>
    <v-flex>
      <v-layout>
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly" align-center>
          <v-flex xs12>
            <toggle-button-wrapper>

              <toggle-button
                :active="unvalidatedLoyaltyScheme === airpointsValue"
                :value="airpointsValue"
                has-suboption
                name="AirpointsButton"
                @click="onLoyaltySchemeChange"
              >
                <airpoints-icon
                  v-if="unvalidatedLoyaltyScheme !== airpointsValue && $vuetify.breakpoint.mdAndUp"
                />
                <airpoints-white-icon
                  v-else-if="unvalidatedLoyaltyScheme === airpointsValue && $vuetify.breakpoint.mdAndUp" />
                <airpoints-mobile-icon
                  v-else-if="unvalidatedLoyaltyScheme !== airpointsValue && $vuetify.breakpoint.smAndDown" />
                <airpoints-mobile-white-icon
                  v-else-if="unvalidatedLoyaltyScheme === airpointsValue && $vuetify.breakpoint.smAndDown" />
              </toggle-button>
            </toggle-button-wrapper>
          </v-flex>
        </v-layout>
      </v-layout>

      <!-- Airpoints -->
      <v-layout :id="airpointsId" v-if="associationDetail && unvalidatedLoyaltyScheme === airpointsValue">
        <v-layout fill-height column justify-space-between>
          <!-- Airpoints titles -->
          <v-flex mb-4>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex class="airpoints-header">
                <typography type="h5">What is your Airpoints&trade; number?</typography>
                <typography light>
                  You can only register one Airpoints™ number per {{ brand }} account.
                  <br>Please provide Airpoints&trade; number, first name and last name as per your Airpoints&trade;
                  account.
                  <br>Airpoints&trade; for Business Accounts are not eligible.
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Airpoints text fields -->
          <v-flex mb-4>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm10 md6>
                <text-input label="Cost centre" v-if="hasCostCentres" @input="onChangeCostCentreCode"
                  :value="unvalidatedCostCentreCode" :key="`CostCentreCode${associationDetailIndex}`"
                  :name="`CostCentreCode${associationDetailIndex}`" @blur="$v.unvalidatedCostCentreCode.$touch()"
                  :rules="[
                    () => $v.unvalidatedCostCentreCode.required || 'This field is required',
                    () => $v.unvalidatedCostCentreCode.maxLength || 'This field is too long',
                    () => $v.unvalidatedCostCentreCode.duplicate || 'Duplicate cost centre',
                  ]" />

                <text-input pattern="\d*" mask="########" label="Airpoints™ number" @input="onChangeAirpointsNumber"
                  :value="unvalidatedAirpointsNumber"
                  :error-messages="getAirpointsValidaitonErrors('unvalidatedAirpointsNumber')"
                  :key="`AirpointsNumber${associationDetailIndex}`" :name="`AirpointsNumber${associationDetailIndex}`"
                  @blur="$v.unvalidatedAirpointsNumber.$touch()" />
                <text-input :maxlength="50" label="First name" @input="onChangeAirpointsFirstName"
                  :value="unvalidatedAirpointsFirstName" :key="`AirpointsFirstName${associationDetailIndex}`"
                  :name="`AirpointsFirstName${associationDetailIndex}`"
                  @blur="$v.unvalidatedAirpointsFirstName.$touch()"
                  :error-messages="getAirpointsValidaitonErrors('unvalidatedAirpointsFirstName')" />
                <text-input :maxlength="50" label="Last name" @input="onChangeAirpointsLastName"
                  :value="unvalidatedAirpointsLastName" :key="`AirpointsLastName${associationDetailIndex}`"
                  :name="`AirpointsLastName${associationDetailIndex}`" @blur="$v.unvalidatedAirpointsLastName.$touch()"
                  :error-messages="getAirpointsValidaitonErrors('unvalidatedAirpointsLastName')" />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>

      <v-flex mb-12>
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs12>
            <round-button @click="onApply" v-if="unvalidatedLoyaltyScheme" :disabled="isDisabled" class="buttons"
              :loading="associationDetail.isValidating"
              :name="`ApplyCostCentreDetails${associationDetailIndex}`">Confirm</round-button>
            <round-button outline @click="onCancel" v-if="associationDetails.length > 1" class="buttons"
              :disabled="associationDetail.isValidating"
              :name="`CancelCostCentreDetails${associationDetailIndex}`">Cancel</round-button>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import FormGroup from '@/components/atoms/FormGroup.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { mapState } from 'vuex';
import {
  SECTION_TERMS_AND_CONDITIONS,
  SECTION_LOYALTY_DETAILS,
  FIELD_LOYALTY_TYPE,
  FIELD_LOYALTY_TYPE_AIRPOINTS
} from '@/constants/html-ids';
import { AIRPOINTS, BRAND, } from '@/constants/form';
import {
  required,
  requiredIf,
  maxLength,
  minLength
} from 'vuelidate/lib/validators';
import AirpointsIcon from '@/assets/icons/airpoints.svg';
import AirpointsWhiteIcon from '@/assets/icons/airpoints-white.svg';
import AirpointsMobileIcon from '@/assets/icons/airpoints-mobile.svg';
import AirpointsMobileWhiteIcon from '@/assets/icons/airpoints-mobile-white.svg';
import ToggleButtonWrapper from '@/components/atoms/ToggleButtonWrapper.vue';
import ToggleButton from '@/components/molecules/ToggleButton.vue';
import { } from '@/constants/form';

export default {
  props: {
    associationDetail: Object,
    associationDetailIndex: Number
  },
  components: {
    Typography,
    FormSection,
    FormGroup,
    Checkbox,
    RoundButton,
    TextInput,
    MobileFullPageWrapper,
    ToggleButtonWrapper,
    ToggleButton,
    AirpointsIcon,
    AirpointsMobileIcon,
    AirpointsWhiteIcon,
    AirpointsMobileWhiteIcon,
  },
  watch: {
    '$v.$invalid': function (value) {
      this.$store.dispatch('loyaltyDetails/changeInputIsValidByIndex', {
        value: !value,
        index: this.associationDetailIndex
      });
    }
  },
  computed: {
    isDisabled() {
      const { isValidating, apiIsValid } = this.associationDetail;
      return this.$v.$invalid || isValidating || apiIsValid;
    },
    ...mapState({
      hasCostCentres: state => state.associationLevel.hasCostCentres,
      associationDetails: state => state.loyaltyDetails.associationDetails,
      editIndex: state => state.loyaltyDetails.editIndex
    }),
    loyaltyTypeId: () => FIELD_LOYALTY_TYPE,
    airpointsId: () => FIELD_LOYALTY_TYPE_AIRPOINTS,
    airpointsValue: () => AIRPOINTS,
    brand: () => BRAND
  },
  data() {
    const { associationDetail } = this;
    return {
      unvalidatedLoyaltyScheme: associationDetail.loyaltyScheme,
      unvalidatedCostCentreCode: associationDetail.costCentreCode,
      unvalidatedAirpointsNumber: associationDetail.airpointsNumber,
      unvalidatedAirpointsFirstName: associationDetail.airpointsFirstName,
      unvalidatedAirpointsLastName: associationDetail.airpointsLastName
    };
  },
  methods: {
    getAirpointsValidaitonErrors(key) {
      const { $v } = this;
      const { isValidating, apiIsValid } = this.associationDetail;

      let errors = [];

      if (isValidating || (!$v[key].$dirty && apiIsValid !== false)) {
        return [];
      }

      if (!$v[key].required) {
        errors = [...errors, 'This field is required'];
      }

      // dont show error message when apiIsValid is null or true
      // when apiIsValid = null it means the api validation hasn't been performed
      if (apiIsValid === false) {
        errors = [...errors, 'This field is invalid'];
      }

      return errors;
    },
    async onLoyaltySchemeChange(value) {
      await this.resetLoyaltyScheme();
      this.unvalidatedLoyaltyScheme = value;

      if (this.$vuetify.breakpoint.smAndDown) {
        await this.$nextTick();

        switch (value) {
          case AIRPOINTS:
            this.$vuetify.goTo(`#${FIELD_LOYALTY_TYPE_AIRPOINTS}`);
            break;
          default:
            break;
        }
      }
    },
    resetApiIsValid() {
      this.$store.dispatch('loyaltyDetails/changeApiIsValidByIndex', {
        index: this.associationDetailIndex,
        value: null
      });
    },
    async onApply() {
      let success;
      if (this.unvalidatedLoyaltyScheme === AIRPOINTS) {
        success = await this.$store.dispatch(
          'loyaltyDetails/validateAirpoints',
          {
            index: this.associationDetailIndex,
            airpointsNumber: this.unvalidatedAirpointsNumber,
            airpointsFirstName: this.unvalidatedAirpointsFirstName,
            airpointsLastName: this.unvalidatedAirpointsLastName
          }
        );
      }
      if (success) {
        this.$store.dispatch('loyaltyDetails/changeLoyaltyScheme', {
          value: this.unvalidatedLoyaltyScheme,
          index: this.associationDetailIndex
        });

        this.$store.dispatch('loyaltyDetails/changeCostCentreCode', {
          value: this.unvalidatedCostCentreCode,
          index: this.associationDetailIndex
        });


        this.$store.dispatch('loyaltyDetails/changeAirpointsNumber', {
          value: this.unvalidatedAirpointsNumber,
          index: this.associationDetailIndex
        });

        this.$store.dispatch('loyaltyDetails/changeAirpointsFirstName', {
          value: this.unvalidatedAirpointsFirstName,
          index: this.associationDetailIndex
        });

        this.$store.dispatch('loyaltyDetails/changeAirpointsLastName', {
          value: this.unvalidatedAirpointsLastName,
          index: this.associationDetailIndex
        });

        this.$store.dispatch('loyaltyDetails/changeInputIsValidByIndex', {
          value: success,
          index: this.associationDetailIndex
        });

        this.$store.dispatch('loyaltyDetails/setEditIndex', null); // reset editIndex
      }
    },
    onCancel() {
      // if cancelling a newly added details option remove it
      if (!this.associationDetail.loyaltyScheme) {
        this.$store.dispatch(
          'loyaltyDetails/removeAssociationDetails',
          this.associationDetailIndex
        );
      } else {
        // set apiIsValid to true when cancelling to prevent
        // not being able to go to next section
        this.$store.dispatch('loyaltyDetails/changeApiIsValidByIndex', {
          index: this.associationDetailIndex,
          value: true
        });
      }
      this.$store.dispatch('loyaltyDetails/setEditIndex', null);
    },
    onChangeCostCentreCode(value) {
      this.unvalidatedCostCentreCode = value;
      this.resetApiIsValid();
    },
    onChangeAirpointsNumber(value) {
      this.unvalidatedAirpointsNumber = value;
      this.resetApiIsValid();
    },
    onChangeAirpointsFirstName(value) {
      this.unvalidatedAirpointsFirstName = value;
      this.resetApiIsValid();
    },
    onChangeAirpointsLastName(value) {
      this.unvalidatedAirpointsLastName = value;
      this.resetApiIsValid();
    },
    async resetLoyaltyScheme() {
      this.unvalidatedLoyaltyScheme = null;
      this.unvalidatedCostCentreCode = '';
      this.unvalidatedAirpointsNumber = '';
      this.unvalidatedAirpointsFirstName = '';
      this.unvalidatedAirpointsLastName = '';
      this.$v.$reset();
    },
    async onNextSection() {
      this.$store.dispatch('loyaltyDetails/changeSectionComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${SECTION_TERMS_AND_CONDITIONS}`);
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch(
          'navigation/addSectionInView',
          SECTION_LOYALTY_DETAILS
        );
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_LOYALTY_DETAILS
        );
      }
    }
  },
  validations: {
    unvalidatedLoyaltyScheme: {
      required
    },
    unvalidatedCostCentreCode: {
      required: requiredIf(function () {
        return this.hasCostCentres === true;
      }),
      maxLength: maxLength(30),
      duplicate: function (code) {
        const associationDetails = this.$store.state.loyaltyDetails
          .associationDetails;
        // Wont be a duplicate if there is only 1 associationDetail
        if (associationDetails.length <= 1) {
          return true;
        }

        const hasDuplicateCostCentreCode = associationDetails
          .map(c => c.costCentreCode)
          // filter out the associationDetails we are editing or if costCentreCode is null
          .filter((c, i) => i !== this.editIndex && !!c)
          .some(c => c.toLowerCase() == code.toLowerCase());

        return !hasDuplicateCostCentreCode;
      }
    },
    unvalidatedAirpointsNumber: {
      required: requiredIf(
        nestedModel => nestedModel.unvalidatedLoyaltyScheme === AIRPOINTS
      )
    },
    unvalidatedAirpointsFirstName: {
      required: requiredIf(
        nestedModel => nestedModel.unvalidatedLoyaltyScheme === AIRPOINTS
      )
    },
    unvalidatedAirpointsLastName: {
      required: requiredIf(
        nestedModel => nestedModel.unvalidatedLoyaltyScheme === AIRPOINTS
      )
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.buttons {
  margin-right: 20px;
  margin-bottom: 20px;
}

.airpoints-header {
  margin-bottom: 1.5rem;
}
</style>

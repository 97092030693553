<template>
  <div class="page">
    <no-cost-centres-dialog />
    <invalid-accumulation-level-dialog />
    <association-level />
    <account-number />
    <loyalty-details />
    <terms-and-conditions />
    <confirm-remove-loyalty-details />
  </div>
</template>

<script>
import NoCostCentresDialog from '@/components/organisms/NoCostCentresDialog.vue';
import InvalidAccumulationLevelDialog from '@/components/organisms/InvalidAccumulationLevelDialog.vue';
import AssociationLevel from '@/components/organisms/AssociationLevel.vue';
import AccountNumber from '@/components/organisms/AccountNumber.vue';
import LoyaltyDetails from '@/components/organisms/LoyaltyDetails.vue';
import TermsAndConditions from '@/components/organisms/TermsAndConditions.vue';
import ConfirmRemoveLoyaltyDetails from '@/components/molecules/ConfirmRemoveLoyaltyDetails.vue';

export default {
  components: {
    NoCostCentresDialog,
    InvalidAccumulationLevelDialog,
    AssociationLevel,
    AccountNumber,
    LoyaltyDetails,
    TermsAndConditions,
    ConfirmRemoveLoyaltyDetails
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.page {
  position: relative;
  color: $charcoal;
  padding: rem(0 20px);

  @include sm {
    padding: rem(0);
  }
}
</style>

<template>
  <modal v-model="removeLoyaltyDetailsDialogOpen">
    <header class="dialog-header">
      <typography type="h3">Are you sure you want to remove these loyalty details?</typography>
    </header>

    <round-button
      outline
      @click="onClose"
      slot="left-button"
      name="removeLoyaltyDetailsDialogNoButton"
    >No, keep loyalty details</round-button>
    <round-button
      slot="right-button"
      @click="onRemove"
      name="removeLoyaltyDetailsDialogYesButton"
    >Yes, remove loyalty details</round-button>
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';

export default {
  components: {
    Modal,
    Typography,
    RoundButton
  },
  computed: {
    removeLoyaltyDetailsDialogOpen: {
      get() {
        return this.$store.state.ui.removeLoyaltyDetailsDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/toggleRemoveLoyaltyDetailsDialogOpen', value);
      }
    }
  },
  methods: {
    onRemove() {
      const index = this.$store.state.ui.removeLoyaltyDetailsIndex;
      this.$store.dispatch('loyaltyDetails/removeAssociationDetails', index);

      this.$store.dispatch('ui/toggleRemoveLoyaltyDetailsDialogOpen', false);
    },
    onClose() {
      this.$store.dispatch('ui/toggleRemoveLoyaltyDetailsDialogOpen', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
</style>

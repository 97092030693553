<template>
  <div
    :class="[
      'toggle-button',
      {'toggle-button--active': active},
      {'toggle-button--has-suboption': hasSuboption},
      {'toggle-button--has-label': !!label},
      {'toggle-button--no-icon': !$slots.default},
      {'toggle-button--no-label': !label}
    ]"
    @click="onClick"
  >
    <span class="button-content">
      <slot/>
    </span>
    <span v-if="label" class="toggle-button-label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hasSuboption: {
      type: Boolean,
      default: false
    },
    value: undefined
  },
  methods: {
    onClick() {
      this.$emit('click', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.toggle-button {
  position: relative;
  outline: 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  border-radius: rem(8px);
  background-color: #f8f8f8;
  padding: rem(0 20px 0 100px);
  height: rem(60px);
  text-align: center;
  margin: rem(0 10px 10px 0);
  transition: all 0.3s ease;
  cursor: pointer;
  user-select: none;

  @include md {
    width: rem(150px);
    min-height: rem(150px);
    padding: rem(20px);
    flex-direction: column;
    justify-content: flex-end;
  }

  &:hover {
    box-shadow: 0 0 20px 0 rgba(202, 202, 202, 0.5);
  }

  &--no-icon {
    padding: rem(0 20px);
  }

  /deep/ svg {
    path {
      color: $dark-blue;
    }
  }

  &--active {
    background-color: $mid-orange;
    color: $white;

    /deep/ svg {
      path {
        fill: $white;
      }
    }

    &.toggle-button--has-suboption {
      @include md {
        transform: translateY(rem(10px));

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 100%;
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 12px solid $mid-orange;
          transform: translate(-50%, 0);
        }
      }
    }
  }
}

.button-content {
  display: inline-block;
  min-width: rem(90px);
  transform: scale(0.5);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  @include md {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 55%;
  }

  /deep/ svg {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }

  .toggle-button--has-label & {
    width: auto;

    @include md {
      top: 40%;
    }
  }

  .toggle-button--no-label & {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  > * {
    margin-bottom: 0;
  }
}

.toggle-button-label {
  cursor: pointer;

  @include md {
    width: 100%;
  }

  .toggle-button--no-icon & {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include md {
      font-size: rem(20px);
      width: 65%;
    }
  }
}
</style>

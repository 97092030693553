<template>
  <form-section
    :id="sectionId"
    @visibility="onChangeSectionVisibility"
    v-if="associationLevelSectionComplete"
  >
    <form-group>
      <mobile-full-page-wrapper :id="accountNumberId">
        <div>
          <typography type="h5">What is your account number?</typography>
          <typography>This is your {{ brand }} account number. You can find it on your {{ brand }} card or invoice.</typography>
          <v-layout mb-4>
            <v-flex md7 lg5>
              <text-input
                v-model="accountNumber"
                :rules="[
                  () => $v.accountNumber.required || 'This field is required',
                  () => $v.accountNumber.maxLength || 'This field is invalid',
                  () => $v.accountNumber.accountNumber || 'Invalid account number',
                ]"
                label="Account Number"
                name="AccountNumber"
                no-margin
                @blur="$v.accountNumber.$touch()"
                :maxlength="8"
              />
            </v-flex>
          </v-layout>
        </div>
      </mobile-full-page-wrapper>

      <v-flex class="no-grow" v-if="!accountNumberSectionComplete">
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs10 sm6 md12>
            <round-button :disabled="$v.$invalid" @click="onNextSectionClick">Next: Loyalty details</round-button>
          </v-flex>
        </v-layout>
      </v-flex>
    </form-group>
  </form-section>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import FormGroup from '@/components/atoms/FormGroup.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { mapState } from 'vuex';
import {
  FIELD_ACCOUNT_NUMBER,
  SECTION_ACCOUNT_NUMBER,
  SECTION_LOYALTY_DETAILS
} from '@/constants/html-ids';
import { BRAND } from '@/constants/form';
import { required, maxLength } from 'vuelidate/lib/validators';
import { accountNumber } from '@/validators';

export default {
  components: {
    Typography,
    FormSection,
    FormGroup,
    Checkbox,
    RoundButton,
    TextInput,
    MobileFullPageWrapper
  },
  watch: {
    '$v.$invalid': function (value) {
      this.$store.dispatch('accountNumber/changeInvalid', value);
    }
  },
  computed: {
    accountNumber: {
      get() {
        return this.$store.state.accountNumber.accountNumber;
      },
      set(value) {
        this.$store.dispatch('accountNumber/setAccountNumber', value);
      }
    },
    ...mapState({
      associationLevelSectionComplete: state =>
        state.associationLevel.sectionComplete,
      accountNumberSectionComplete: state => state.accountNumber.sectionComplete
    }),
    sectionId: () => SECTION_ACCOUNT_NUMBER,
    accountNumberId: () => FIELD_ACCOUNT_NUMBER,
    brand: () => BRAND
  },
  methods: {
    async onNextSectionClick() {
      this.$store.dispatch('accountNumber/changeSectionComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${SECTION_LOYALTY_DETAILS}`);
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch(
          'navigation/addSectionInView',
          SECTION_ACCOUNT_NUMBER
        );
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_ACCOUNT_NUMBER
        );
      }
    }
  },
  validations: {
    accountNumber: {
      required,
      maxLength: maxLength(8),
      accountNumber
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
</style>

<template>
  <modal v-model="noCostCentresDialogOpen" center-buttons>
    <v-layout justify-center>
      <v-flex xs10 sm8 md10>
        <typography type="h3">Sorry, we can't link an Airpoints&trade; number with a cost centre if your
          account hasn't been set up that way.</typography>
      </v-flex>
    </v-layout>

    <round-button slot="left-button" @click="onOk">OK</round-button>
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { BRAND, AIRPOINTS } from '@/constants/form';

export default {
  components: {
    Modal,
    Typography,
    RoundButton
  },
  computed: {
    noCostCentresDialogOpen: {
      get() {
        return this.$store.state.ui.noCostCentresDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/toggleNoCostCentresDialogOpen', value);
      }
    },
    brand: () => BRAND,
    airpoints: () => AIRPOINTS
  },
  methods: {
    onOk() {
      this.noCostCentresDialogOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
</style>
